
@font-face {
  font-family: 'Comfortaa';
  src: url('fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf') format('truetype');
}

.App {
  font-family: 'Comfortaa', sans-serif;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

ul {
  list-style-type: none;
  padding: 0;
}

.menu ul {
  background-color: #222;
  margin: 0;
}

.menu li {
  font-family: sans-serif;
  font-size: 1.2em;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #888;
}

.oldImprintHeader {
  background-color: #f0f0f0;
  font: 400 20px Arial;
  padding: 2px 2px 2px 2px;
  border-width: 1px;
  border-radius: 0px;
  border-style: solid;
  border-color: rgb(170, 170, 170);
}

.imprintHeader {
  color: rgb(0, 0, 0);
  text-decoration: none;
  background: #f0f0f0;
  padding: 4px 20px;
  border-width: 5px;
  border-radius: 4px;
  border-color:rgb(0, 0, 0);
  font-size: 1.2em;
}


.imprintHeader a {
  color: black;
  text-decoration: none;
}

.imprintHeader a:link {
  color: black;
  text-decoration: none;
}

.imprintHeader a:hover {
  color: red;
  text-decoration: none;
}

.imprintHeader a:visited {
  color: black;
  text-decoration: none;
}

.imprintHeader a:active {
  color: hotpink;
  text-decoration: none;
}