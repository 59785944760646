.table {
    border: 1px solid blue;
    padding: 0 10px 10px 10px;
    word-wrap: break-word;
    table-layout: fixed;
}
.table .tr :last-child .td {
    border-bottom: 10px;
    border: 20px solid red;
}
.table .th, .table .td {
    padding: 5px;
    border-bottom: 1px solid rgb(255, 2, 2);
    border-right: 1px solid rgb(255, 0, 0);
    background-color: #fff;
    overflow: hidden;
}
.table .th :last-child, .table .td :last-child {
    border-right: 2px;
}
.table .th .resizer, .table .td .resizer {
    display: inline-block;
    width: 5px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
}
.table .th .resizer.isResizing, .table .td .resizer.isResizing {
    background: red;
}
.table.sticky {
    overflow: scroll;
}
.table.sticky .header, .table.sticky .footer {
    position: sticky;
    z-index: 1;
    width: fit-content;
}
.table.sticky .header {
    top: 0;
    box-shadow: 0px 3px 3px #ccc;
}
.table.sticky .footer {
    bottom: 0;
    box-shadow: 0px -3px 3px #ccc;
}
.table.sticky .body {

    z-index: 0;
}
.table.sticky [data-sticky-td] {
    position: sticky;
}
.table.sticky [data-sticky-last-left-td] {
    box-shadow: 2px 0px 3px #ccc;
}
.table.sticky [data-sticky-first-right-td] {
    box-shadow: -2px 0px 3px #ccc;
}
