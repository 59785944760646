

.imprint {
  margin: auto;
  font: 400 12px Arial;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f1f1f1;
  padding: 4px 4px 4px 4px;
  width: 750px;
}

.topNavbar {
  font-size: 18px;
}

.invisibleCell {
  word-wrap: break-word;
  border: none;
  background-color: #f1f1f1;
}

.dropdown {
  background-color: #d1d1d1;
}

.padded_flex {
    display: flex;
    padding: 2px 2px 2px 2px;
}

.center_flex {
  display: flex;
  justify-content: center;
  padding: 2px 2px 2px 2px;
}

.text16 {
  font: 400 16px system-ui;
}

.header {
  font: 400 18px system-ui;
  font-weight: bold;
}

.padded2 {
  padding: 2px 2px 2px 2px;
}

.padded4 {
    padding: 4px 4px 4px 4px;
}

.padded8 {
    padding: 8px 8px 8px 8px;
}

.bold {
  font-weight: bold;
}

.textInput {
  box-sizing: border-box;
  margin: 0em;
  font: 400 14px system-ui;
  padding: 2px 2px 2px 2px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(170, 170, 170);
  border-radius: 5px;
}

.button {
  background-color: #d1d1d1;
  font: 400 14px system-ui;
  padding: 2px 2px 2px 2px;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  border-color: rgb(170, 170, 170);
}

.mini-button {
  background-color: #d1d1d1;
  font: 400 12px system-ui;
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  border-color: rgb(170, 170, 170);
}

.buttonActive {
  width: 150px;
  color: white;
  text-align: center;
  background-color: #5f8aa3;
  box-sizing: border-box;
  font: 400 14px system-ui;
  padding: 1px 7px 2px;
  border-width: 1px;
  border-style: solid;
  border-color: #366681 #366681 #366681;
}

.buttonInactive {
  width: 150px;
  text-align: center;
  background-color: #e9e9e9;
  box-sizing: border-box;
  font: 400 14px system-ui;
  padding: 1px 7px 2px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(170, 170, 170);
}

.button:hover,
.mini-button:hover,
.buttonActive:hover,
.buttonInactive:hover {
  font-weight: bold;
}

.button:active,
.mini-button:active,
.buttonActive:active,
.buttonInactive:active {
  background-color: #5f8aa3;
  font-weight: bold;
}



.token {
  background-color: #d1d1d1;
  font: 400 14px system-ui;
  padding: 0px 5px 0px 5px;
  margin-left: 5px;
  text-align: center;
  border-width: 2px;
  border-radius: 15px;
  border-style: solid;
  border-color: rgb(170, 170, 170);
  float: right;
}



.infobox {
  font: 400 12px Arial;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f1f1f1;
  padding: 4px 4px 4px 4px;
}

.menuBox {
  border: 1px solid #a3a3a3;
  background-color: #f1f1f1;
  padding: 4px 4px 4px 4px;
  border-radius: 5px;
}

.login {
  font: 400 16px system-ui;
  padding: 10px 10px 10px 10px;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-placeholder {
  font: 400 14px Arial;
  text-align: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-placeholder__spinner {
  animation: spinner 3s linear infinite;
  font: 400 20px Arial;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky !important;
  top: 0;
  align-self: flex-start;
  margin: 0 0 0 0;
  z-index: 2;
}

.scrollable-table {
  height: 780px;
  overflow-y: scroll;
  padding: 0 10px 10px 10px;
}

.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

/* CSS for KeggModules */
canvas {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

table, th, td {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #000000;
  background-color: #f7f7f7;
  text-align: left;
  padding: 2px;
  font-size: 12px;
}

.koDetailsTable {
  border: 1px solid #000000;
  border-radius: 3px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.generalTable {
  border: 0px solid #000000;
  border-radius: 3px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}



.moduleDescriptionText {
  display: inline-block;
  text-align: center;
  background-color: #ffffff;
  width: 298px;
  font: 400 13px Arial;
  padding: 0px 0px 0px 0px;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
  border-color: rgb(170, 170, 170);
}



.kegg_modules {
  display: flex;
  align-content: flex-start;
}

.module_diagram {
  display: flex;
  flex-direction:column;
  align-items: left;
  font-size: 16px;
}

.ko_mags_bar_chart {
  height: 500px;
  display: table !important;
  align-content: flex-end !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.koDetailsColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

.noWordbreakColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  word-wrap: normal;
  overflow-wrap: normal;
}

.wordbreakColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.debugBox {
  text-align: left;
  text-indent: 0px;
  color: rgb(73, 163, 0);
  background-color: rgb(0, 0, 0);
  font: 400 11px system-ui;
  border-style: solid;
  border-width: 1px;
  border-color:rgb(0, 0, 0);
  display: block;
  margin: 1em;
  padding: 3px 3px 3px; 
}

/* Top Navbar */
.navbar {
  display: flex;
  justify-content: center;
}

.topNavbar {
  font-size: 18px;
}

.navbarlink-inactive {
  color: rgb(0, 0, 0);
  text-decoration: none;
  background: #f0f0f0;
  padding: 4px 20px;
  border-radius: 4px;
  margin-right: 10px;
}

.navbarlink-inactive:hover {
  font-weight: bold;
  color: #5f8aa3;
}

.navbarlink-active {
  color: #fff;
  text-decoration: none;
  background: #5f8aa3;
  padding: 4px 20px;
  border-radius: 4px;
  margin-right: 10px;
}